import { Comment } from "vue";
import { ContentDataFormat, NumberFormat } from '../models/enums';
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import {
    at, cloneDeep, defaults, delay, difference, drop, endsWith, eq, every, filter, find, first, flatMap, forEach, get, hasIn, includes,
    invoke, isArray, isBoolean, isDate, isEmpty, isEqual, isFunction, isNaN, isNil, isNumber, isObject, isString, join, keys, last, map,
    pickBy, reduce, reduceRight, remove, repeat, replace, reverse, size, some, sortBy, split, startsWith, take, toLower, toPairs, trim, uniq
} from "./core";

const getParseGeneric = (parseMethod, target, path, defaultValue) => invoke({ parseNumber, parseBool, parseDate, parseDateString, parseLuxonDateTime }, parseMethod, get(target, path, null), defaultValue);

const defaultButtonOptions = { showAddAnother: true, okTitle: "Save", cancelTitle: "Cancel", anotherTitle: "Save and Add Another", onOk: ()=>true, onCancel: ()=>true };

const arrayKeysDiffBy = (keyPredicate) => (left, right) => filter(left, (leftItem) => !some(right, (rightItem) => keyPredicate(leftItem, rightItem)))
const arrayChangeDiffBy = (keyPredicate, changeCheckPredicate) => (left, right) => filter(left, (leftItem) => some(right, (rightItem) => keyPredicate(leftItem, rightItem) && !changeCheckPredicate(leftItem, rightItem)))
export const makeArrayDiffFunc = (keyPredicate, changeCheckPredicate) => (newArray, originalArray) => {

    let keys = (a, b) => a == b;

    if (isString(keyPredicate)) {
        keyPredicate = [keyPredicate];
    }

    if (isFunction(keyPredicate)) {
        keys = keyPredicate;
    }
    else if (isArray(keyPredicate)) {
        keys = (a, b) => every(keyPredicate, (k) => a[k] == b[k]);
    }

    let checkers = (a, b) => a == b;

    if (isString(changeCheckPredicate)) {
        changeCheckPredicate = [changeCheckPredicate];
    }

    if (isFunction(changeCheckPredicate)) {
        checkers = changeCheckPredicate;
    }
    else if (isArray(changeCheckPredicate)) {
        checkers = (a, b) => every(changeCheckPredicate, (k) => a[k] == b[k]);
    }

    let response = {
        deleted: arrayKeysDiffBy(keys)(originalArray, newArray),    // In the "originalArray" and NOT in the "newArray"
        inserted: arrayKeysDiffBy(keys)(newArray, originalArray),   // In the "newArray" and NOT in the "originalArray"
        updated: arrayChangeDiffBy(keys, checkers)(newArray, originalArray),    // Changes based on the key(s)
    }

    return response;
};

const vnodesAreEmpty = vnodes => every(vnodes, vn => vnodeIsEmpty(vn));
const vnodeIsEmpty = vnode => {
    if(isEmpty(vnode)) return true;
    if(isString(vnode)) return false;
    if(isArray(vnode)) return vnodesAreEmpty(vnode);
    if(vnode?.type === Comment) return true;
    return vnodesAreEmpty(vnode?.children);
};

export function parseNumber(value, defaultValue = NaN) { return isNumber(value) || (!isNil(value) && !isNaN(value) && !eq(value, '')) ? Number(value) : defaultValue; }

export function isValidDate(value) {
    if(isNullOrEmpty(value)) return false;
    let dateVal = new Date(value);
    return isDate(dateVal) && !isNaN(Date.parse(dateVal));
}

export function parseDate(value, defaultValue = null) {
    let defaultVal = defaultValue === "now" ? new Date() : defaultValue;
    return isValidDate(value) ? new Date(value) : defaultVal;
}

export function parseBool(value, defaultValue = false) {
    if (isBoolean(value)) return value;
    if (!isNil(value)) {
        let trueVals = ["true", "yes", "1"];
        let falseVals = ["false", "no", "0"];
        if (includes(trueVals, value.toString().toLowerCase())) return true;
        if (includes(falseVals, value.toString().toLowerCase())) return false;
    }
    return defaultValue;
}

export function parseDateString (value, defaultValue = null, formatString = "iso") {
    let dt = parseLuxonDateTime(value, defaultValue);
    if(isNil(dt) || !dt.isValid) return defaultValue;
    if(formatString === "iso") return dt.toISO();
    let luxFormatString = replace(replaceAll(replaceAll(formatString, "Y", "y"), "D", "d"), "T", "'T'");
    return dt.toFormat(luxFormatString);
}

export function parseLuxonDateTime (value, defaultValue=null) {
    let dateValue = parseDate(value, defaultValue);
    return isNil(dateValue) ? null : DateTime.fromJSDate(dateValue);
}

export const equalsIgnoreCaseAndWhitespace = (string1, string2) => string1.replace(/\s/g, "").toUpperCase() === string2.replace(/\s/g, "").toUpperCase();

export function tryParseNumber(value, callback) {
    let tryVal = parseNumber(value);
    let isValid = !isNaN(tryVal);
    if(isValid && isFunction(callback)) callback(tryVal);
    return isValid;
}

export const getNumber = (target, path, defaultValue = null) => getParseGeneric("parseNumber", target, path, defaultValue);

export const getNumberFrom = (target, paths, defaultValue = NaN) => parseNumber(getFrom(target, paths, null), defaultValue);

export const getDate = (target, path, defaultValue = null) => getParseGeneric("parseDate", target, path, defaultValue);

export const getDateString = (target, path, defaultValue = null) => getParseGeneric("parseDateString", target, path, defaultValue);

export const getLuxonDateTime = (target, path, defaultValue = null) => getParseGeneric("parseLuxonDateTime", target, path, defaultValue);

export function parseDelimitedNumbers(value, delimiter=",", defaultValue=[]) {
    if(isEmpty(value)) return defaultValue;
    let resultArray = split(value, delimiter);
    let result = map(resultArray, n => parseNumber(trim(n), null));
    return filter(result, n => !isNil(n));
}

export function getDelimitedNumbers(target, path, delimiter=",", defaultValue=[]) {
    let stringVal = get(target, path, "");
    return parseDelimitedNumbers(stringVal, delimiter, defaultValue);
}

export const getBool = (target, path, defaultValue = false) => getParseGeneric("parseBool", target, path, defaultValue);

export function isNullOrEmpty(value) {
    if(isNil(value)) return true;
    if(isDate(value) || isNumber(value) || isBoolean(value)) return false;
    if(isString(value)) return isEmpty(trim(value));
    return isEmpty(value);
}

export function getIsEqual(target, path, other, pathOnOther=false, defaultValue=null) {
    let targetValue = get(target, path, defaultValue);
    let otherValue = pathOnOther ? get(other, path, defaultValue) : other;
    return isEqual(targetValue, otherValue);
}

export const getIsNil = (target, path) => isNil(get(target, path));

export const getIsEmpty = (target, path) => isEmpty(get(target, path));

export function getWithNullCheck(target, path, defaultValue) {
    let val = get(target, path, defaultValue);
    if(isNil(val)) return defaultValue;
    return val;
}

export const getIsNullOrEmpty = (target, path) => isNullOrEmpty(get(target, path));

export function getFrom(target, paths, defaultValue) {
    if(isString(paths)) return get(target, paths, defaultValue);
    let result = defaultValue;
    forEach(paths, p => {
        result = get(target, p, defaultValue);
        return result === defaultValue;
    });
    return result;
}

export function getFirst(target, path, defaultValue) {
    return get(first(target), path, defaultValue);
}

export function getFirstNumber(target, path, defaultValue) {
    return getNumber(first(target), path, defaultValue);
}

export function updateAll(collection, key, value) {
    forEach(collection, (item,index) => {
        item[key] = isFunction(value)
            ? value(item,index)
            : value;
    });
}

export function replaceAll(target, pattern, replacement) {
    if(pattern === replacement || includes(replacement, pattern) || !includes(target, pattern)) return target;
    let parsedIndexes = [];
    let result = replace(target, pattern, replacement);
    let nextIndex = result.indexOf(pattern);
    while(nextIndex >= 0 && !includes(parsedIndexes, nextIndex)){
        result = replace(result, pattern, replacement);
        parsedIndexes.push(nextIndex);
        nextIndex = result.indexOf(pattern);
    }
    return result;
}

export function reduceMatchingProp(collection, propName, defaultValue=null){
    return (isEmpty(collection) || !hasIn(collection[0], propName) || collection[0][propName] === defaultValue)
        ? defaultValue
        : reduce(collection,
            (currentVal, item) =>
                currentVal === item[propName]
                    ? currentVal
                    : defaultValue,
            collection[0][propName]
        );
}

export function replaceDelimiter(delimitedString, delimiter, replacement) {
    if(delimiter === replacement) return delimitedString;
    return join(split(delimitedString, delimiter), replacement);
}

export function ensureEndsWith (value, ending) {
    return isString(value) ? endsWith(value, ending) ? value : value + ending : ending;
}

export function ensureStartsWith (value, ensureText) {
    return !isString(value) ? ensureText : (!startsWith(value, ensureText) ? ensureText : "") + value;
}

export function isBase64String (str) {
    try { atob(str); return true; } catch (er) { return false; }
}

export function base64EncodeUnicode (str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(`0x${p1}`)));
}

export function base64DecodeUnicode (str) {
    return decodeURIComponent(map(atob(str).split(''), c => (`%00${c.charCodeAt(0).toString(16)}`).slice(-2)).join(''));
}

export function hexDecode (hex) {
    var result = "";

    if (isNil(hex)) return result;

    var hexSplit = hex.split("\\");

    for(let index = 1; index < hexSplit.length; index++) {
        var xhex = hexSplit[index];
        if (!xhex) {
            continue;
        }
        var hexInt = xhex.slice(1);
        result += String.fromCharCode(parseInt(hexInt, 16));
    }
    return result;
}

export function hexEncode (input) {
    var result = "";

    if (isNil(input)) return result;

    for (let index=0; index < input.length; index++) {
        let hex = input.charCodeAt(index).toString(16);
        result +=  "\\u" + (("0000"+hex).slice(-4));
    }
    return result;
}

export function base64ToUint8Array(base64) {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
}

export function base64ToBlob(base64, blobType) {
    const bytes = base64ToUint8Array(base64);
    return new Blob([bytes], { type: blobType });
}

export function base64ToArrayBuffer(base64) {
    var bytes = base64ToUint8Array(base64);
    return bytes.buffer;
}

export function base64ToObjectUrl(base64, contentType) {
    var blob = base64ToBlob(base64, contentType);
    return URL.createObjectURL(blob);
}

export function htmlToObjectUrl(htmlContent) {
    let htmlValue = htmlContent || '';
    var blob = new Blob([htmlValue], { type: 'text/html' });
    return URL.createObjectURL(blob);
}

export function htmlToText(value, linearize=false) {
    let parser = new DOMParser();
    let htmlDom = parser.parseFromString(value, "text/html");
    let result = $(htmlDom.body).text().trim();
    return linearize
        ? result.replace(/[\n\r]+/g, " ")
        : result;
}

export function listWords(words, conjunction="and") {
    if(!isArray(words)) return "";
    if(words.length === 1) return words[0];
    if(words.length === 2) return `${words[0]} ${conjunction} ${words[1]}`;
    let delimited = join(take(words, words.length-1), ", ");
    return `${delimited}, ${conjunction} ${last(words)}`;
}

export function joinParts(parts, delimiter="", excludeEmptyParts=true) {
    if(!excludeEmptyParts) return join(parts, delimiter);
    if(!isArray(parts)) return "";
    let partsVal = parts.slice();
    remove(partsVal, p=>isEmpty(p) || isNil(p));
    return join(partsVal, delimiter);
}

export function search(targetList, searchText, includeKeys=[], excludeKeys=[], matchCase=false) {
    if(!isArray(targetList) || isEmpty(trim(searchText))) return targetList;
    const parse = val => matchCase ? trim(val) : toLower(trim(val));
    let searchVal = parse(searchText);
    return filter(targetList, val => {
        if(isString(val)) return includes(parse(val), searchVal);
        if(isObject(val)) return some(val, (v,k) => (isEmpty(includeKeys) ? !includes(excludeKeys, k) : includes(includeKeys, k)) && includes(parse(v), searchVal));
        return false;
    });
}

export function differs(arr1, arr2) {
    return isNil(arr1) !== isNil(arr2)
        || size(arr1) !== size(arr2)
        || some(difference(arr1,arr2));
}

export function parseKeyListValue(value, suffix=null) {
    const applySuffixes = keys => map(keys, a => endsWith(trim(a), suffix) ? trim(a) : trim(a) + suffix);
    let keys = !isEmpty(value)
        ? isArray(value)
            ? value
            : isString(value)
                ? split(trim(value, [","," "]), ",")
                : []
        : [];
    return isEmpty(suffix) ? keys : applySuffixes(keys);
}

export function mapLookup(items, keyExpr="id", nameExpr="name", keepObjDefinition=false, keepData=false) {
    const mapItem = item => {
        if(keepObjDefinition) return { ...item, [keyExpr]:parseNumber(item[keyExpr], 0) };
        let result = { id: parseNumber(item[keyExpr], 0), name: item[nameExpr] };
        return keepData
            ? { ...result, data: cloneDeep(item) }
            : result;
    }
    return map(items, item => mapItem(item));
}

export function page(items, skip, take) {
    if(!isArray(items) || isEmpty(items)) return { data: [], totalCount: 0 };
    return { data: take(drop(items, skip), take), totalCount: items.length };
}

export function trimSuffix(toTrim, trim) {
    if (!toTrim || !trim) {
        return toTrim;
    }
    const index = toTrim.lastIndexOf(trim);
    if (index === -1 || (index + trim.length) !== toTrim.length) {
    return toTrim;
    }
    return toTrim.substring(0, index);
}

export function trimPrefix(toTrim, trim) {
    if (!toTrim || !trim) {
        return toTrim;
    }
    const index = toTrim.indexOf(trim);
    if (index !== 0) {
    return toTrim;
    }
    return toTrim.substring(trim.length);
}

export const trimLower = value => isString(value) ? toLower(trim(value)) : value;

export function evalCssObject(cssObj=null) {
    if(isNil(cssObj)) return "";
    let classArray = keys(pickBy(cssObj));
    if(isEmpty(classArray)) return "";
    let fullClassArray = flatMap(classArray, c => split(c, " "));
    return join(uniq(fullClassArray), " ");
}

export function swap(a, b, m="*") {
    if(isArray(a) || isArray(b)) throw "swapValues : This function does not support swapping array values";
    if(typeof a !== typeof b) throw "swapValues : Types for a and b value arguments must match";
    if(isObject(a) && isNil(m)) throw "swapValues : A non-null field name argument must be provided when passing objects for a and b (default is \"*\", in which case the entire object value is swapped)";

    if(isObject(a) && m !== "*")
        [a[m], b[m]] = [b[m], a[m]];
    else
        [a, b] = [b, a];
}

export function coalesce(params, defaultValue=null) {
    let result = defaultValue;

    if (isEmpty(params)) return result;

    forEach(params, (arg, key, allParams) => {
        result = result(allParams, key);

        if (!isNil(result)
            && result
            && result == result /* Treats NaN as null-ish */) {
            return false;
        }
    });

    return result || defaultValue;
}

// lodashCompareFunc = every, some, filter
export function listToListExist(lodashCompareFunc, left, right, comparerFunc) {
    return listCompare(lodashCompareFunc, left, some, right, comparerFunc);
}

// lodashFunc = every, some, filter
// lodashExistFunc = every, some
export function listCompare(lodashFunc, left, lodashExistFunc, right, comparerFunc) {
    let existCheckFunc = (lItem) => {
        // Call with one parameter (left).
        // This is on the assumption that we'll return back an object as expected by lodash.
        if (comparerFunc.length == 1) {
            let matches = comparerFunc(lItem);
            return lodashExistFunc(right, matches);
        }
        else {
            return lodashExistFunc(right, (rItem) => comparerFunc(lItem, rItem));
        }
    }
    return lodashFunc(left, existCheckFunc);
}

export function listEveryExists(left, right, comparerFunc) {
    return listCompare(every, left, some, right, comparerFunc)
}
export function listSomeExists(left, right, comparerFunc) {
    return listCompare(some, left, some, right, comparerFunc)
}
// listFilter(left, lodashExistFunc, right, comparerFunc) {
//     return listCompare(filter, left, lodashExistFunc, right, comparerFunc);
// },

//simple function to wrap delay and return a promise
export const wait = duration => new Promise(resolve => { delay(resolve, duration); });

export const characterCount = s => [...s].reduce((a, c) => (a[c] = a[c] + 1 || 1) && a, {});

export const formatMoney = v => accounting.formatMoney(parseNumber(v, 0), { format: { pos:"%s%v", neg:"(%s%v)", zero:"%s%v" } });

export function findValue(list, field, predicate, fromIndex=0) {
    let targetObj = find(list, predicate, fromIndex);
    return get(targetObj, field, null);
}

export function fieldValues(list, field, includeNil=false) {
    let result = map(list, field);
    let uniqResult = uniq(result);
    if(!includeNil) remove(uniqResult, isNil);
    return uniqResult;
}

export const sortedValues = obj => map(sortBy(toPairs(obj), 0), 1);

export function isUnique({ item, list, keyName="", fieldNames=[], ignoreCase=true }) {
    const extractValues = o => {
        let result = isEmpty(fieldNames) ? sortedValues(o) : at(o, fieldNames);
        return ignoreCase ? map(result, trimLower) : result;
    }

    let itemKey = isEmpty(keyName) ? null : getNumber(item, keyName, null);
    let itemValues = extractValues(item);

    return every(list, listItem => {
        if(!isNil(itemKey) && itemKey === getNumber(listItem, keyName)) return true;
        let listItemValues = extractValues(listItem);
        return differs(itemValues, listItemValues);
    });
}

export const isDuplicate = opts => !isUnique(opts);

export const slotIsEmpty = (component, slotName) => {
    let slotNodes = invoke(component, `$slots.${slotName}`) || [];
    return vnodesAreEmpty(slotNodes);
};

export const createUuid = () => uuidv4();


export function parseContentFormat (content, returnFormat) {
    if (content) {
        let contentString = '';
        if (typeof content === 'object') {
            contentString = new TextEncoder('utf-8').decode(content);
        } else if (isBase64String(content)) {
            contentString = atob(content);
        } else {
            contentString = content;
        }

        switch (returnFormat) {
            case ContentDataFormat.UnencodedString:
                return contentString;
            case ContentDataFormat.Base64String:
                return btoa(contentString);
            case ContentDataFormat.Uint8Array:
                return new TextEncoder('utf-8').encode(contentString);
        }
    }
    return '';
}

export function toRomanNumeral (val) {
    const rn = { I: 1, V: 5, X: 10, L: 50, C: 100, D: 500, M: 1000 };
    const rnums = keys(rn);
    return reduce(reverse(split(val.toString(), '')), (a, v, i) => {
        if (v === '0') return String(a);
        if (v < '4') return repeat(rnums[2 * i], v) + a;
        if (v === '4') return rnums[2 * i] + rnums[2 * i + 1] + a;
        if (v < '9') return rnums[2 * i + 1] + repeat(rnums[2 * i], v - 5) + a;
        return rnums[2 * i] + rnums[2 * i + 2] + a;
    }, '');
}

export function fromRomanNumeral (val) {
    const rn = { I: 1, V: 5, X: 10, L: 50, C: 100, D: 500, M: 1000 };
    return reduceRight(val, (a, v, i, c) => {
        if (i === c.length - 1) return rn[v];
        if (rn[v] < rn[c[i + 1]]) return a - rn[v];
        return a + rn[v];
    }, 0);
}

export function toAlphaFromInt (val) {
    let currentVal = val;
    let result = '';
    while (--currentVal >= 0) {
        result = String.fromCharCode(('a').charCodeAt(0) + currentVal % 26) + result;
        currentVal /= 26;
    }
    return result;
}

export function toIntFromAlpha (val) {
    return reduce(reverse(val.split('')), (a, v, i) => {
        return ((v.toLowerCase().charCodeAt(0) - 96) * Math.pow(26, i)) + a;
    }, 0);
}

export function toListNumberChar (numVal, numFormat) {
    numVal = numVal || 1;
    switch (numFormat) {
        case NumberFormat.Letters: return toAlphaFromInt(numVal);
        case NumberFormat.CapitalLetters: return toAlphaFromInt(numVal).toUpperCase();
        case NumberFormat.RomanNumbers: return toRomanNumeral(numVal);
        case NumberFormat.SmallRomanNumbers: return toRomanNumeral(numVal).toLowerCase();
    }
    return numVal;
}

export function toListNumberValue (charVal, numFormat) {
    if (!charVal) return 1;
    switch (numFormat) {
        case NumberFormat.Letters:
        case NumberFormat.CapitalLetters: return toIntFromAlpha(charVal);
        case NumberFormat.RomanNumbers:
        case NumberFormat.SmallRomanNumbers: return fromRomanNumeral(charVal.toUpperCase());
    }
    return isNaN(charVal) ? 1 : Number(charVal);
}

export function alphaCharCodes (upperCase = false) {
    let alphabet = 'abcdefghijklmnopqrstuvwxyz';
    if (upperCase) alphabet = alphabet.toUpperCase();
    return map(alphabet.split(''), c => c.charCodeAt(0));
}

export function romanCharCodes (upperCase = false) {
    let romanNumerals = 'ivxlcdm';
    if (upperCase) romanNumerals = romanNumerals.toUpperCase();
    return map(romanNumerals.split(''), c => c.charCodeAt(0));
}

export function numberCharCodes () {
    let numbers = '0123456789';
    return map(numbers.split(''), c => c.charCodeAt(0));
}

export function dialogButtons(options) {
    let opts = defaults({}, options, defaultButtonOptions);
    let result = [
        { title: opts.cancelTitle, automationId: "btn_dm_modal_cancel", cssClass: "btn btn-secondary", onClick: opts.onCancel },
        { title: opts.okTitle, automationId: "btn_dm_modal_save", cssClass: "btn btn-primary", onClick: e => opts.onOk(e, false) }
    ];
    if(opts.showAddAnother) {
        result.splice(1, 0, { title: opts.anotherTitle, automationId: "btn_dm_modal_save_and_another", cssClass: "btn btn-primary", onClick: e => opts.onOk(e, true) })
    }
    return result;
}


export const mapAsKeys = (list, iteratee) => {
    let result = {};
    _.forEach(list, (val, index) => {
        result[val] = iteratee(val, index);
    });
    return result;
};

export const filterSize = (collection, predicate) => _.size(_.filter(collection, predicate));

const parseFaStyle = styleName => {
    if(styleName?.length <= 4)
        return styleName;
    switch(styleName) {
        case "fa-regular": return "far";
        case "fa-solid": return "fas";
        case "fa-sharp": return "fass";
        case "fa-duotone": return "fad";
        case "fa-light": return "fal";
        case "fa-thin": return "fat";
    }
    return styleName;
};

export const getSvgSymbolId = selector => {
    let parts = _.split(selector, " ");
    return _.startsWith(selector, "rq-")
        ? selector
        : `rq-${_.replace(parts[1], "fa", parseFaStyle(parts[0]))}`;
};

export const getSvgSymbolIcon = (selector, size=null, className=null) => {
    let symbolId = getSvgSymbolId(selector);
    let classAttr = _.isEmpty(className)
        ? _.isEmpty(size)
            ? "rq-icon-symbol"
            : `rq-icon-symbol-${size}`
        : className;
    return `<svg class="${classAttr}"><use href="#${symbolId}"></use></svg>`;
};

export const isDateOnWeekend = dateValue => {
    let dt = _.parseLuxonDateTime(dateValue);
    return !_.isNil(dt) 
        && dt?.isValid
        && (
            dt.weekday === 6 
            || dt.weekday === 7
        );
};
