/*************************************************************/
//Plugin to install all Horizon plugin/component dependencies
/*************************************************************/

// 3rd party
import VueShave from "vue-shave";
import VueGtag from "vue-gtag";
import Maska from "maska";

// import { BButton, BAlert, BBadge, BCollapse, BDropdown, BFormCheckbox, BFormRadio, BNav, BNavbar, BLink, BPopover, BTab } from "bootstrap-vue-3";
import BootstrapVue3, { BButton as BBtn }  from "bootstrap-vue-3";
import VueLogger from "vuejs3-logger";
import vfmPlugin from "vue-final-modal";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import {
    FontAwesomeIcon,
    FontAwesomeLayers,
    FontAwesomeLayersText
} from "@fortawesome/vue-fontawesome";

// storejs
import storeLocaleStorage from "storeLocalStorage";
import storeDefaultsPlugin from "storeDefaultsPlugin";
import storeExpirePlugin from "storeExpirePlugin";

// rq vendor
import RqDx from "vendor/rq-devextreme-vue.esm";
import { RqUi, RqError } from "vendor/rq-ui-vue.esm";

// plugins
import { RqDiff, RqAccess, RqApi, RqApp, RqStore, RqToast, RqUtils, RqClipboard, RqDialog, RqBusy } from "./shared/plugins";
import { RqVueEvents } from "./app.events";

//directives
import RqDirectives from "./shared/directives";

// Globally registered components
import * as GlobalComponents from "./shared/components/global";

// Make sure we don't pollute analytics with development information.
let measurementId = "none";
if (_.includes(_.toLower(location.hostname), "rqhorizon.com")) {
    measurementId = "G-JMQGTWWEN5";
}
const pluginOptions = {
    logger: { logLevel: _.isNil(appSettings.logLevel) ? "error" : appSettings.logLevel, stringifyArguments: false, showLogLevel: true },
    rqClipboard: { maxHistoryLength: 10 },
    rqApi: { suppressErrors: false },
    rqStore: { storages: [storeLocaleStorage], plugins:[storeDefaultsPlugin, storeExpirePlugin] },
    rqError: { overrideDevMode: false },
    shave: {
        throttle: 400,    // Throttle length in ms
        spaces: true,     // Spaces config (see shave documentation)
        height: 100        // Default shave height (see shave documentation)
    },
    gtag: {
        config: {
            id: measurementId
        },
    }
}

export const createAppInstaller = router => ({
    install (app) {
        app.mixin({
            beforeCreate() {
                this._hzn_uid = _.createUuid();
            }
        });

        // Register plugins with vue
        app.use(VueShave, pluginOptions.shave);
        app.use(VueGtag, pluginOptions.gtag, router);
        app.use(VueLogger, pluginOptions.logger);
        app.use(BootstrapVue3);
        app.use(PerfectScrollbar);
        app.use(Maska);
        app.use(vfmPlugin); //vue-final-modal

        app.use(RqUi);
        app.use(RqStore, pluginOptions.rqStore);
        app.use(RqBusy);
        app.use(RqError, pluginOptions.rqError);
        app.use(RqClipboard, pluginOptions.rqClipboard);
        app.use(RqApi, pluginOptions.rqApi);
        app.use(RqDiff);
        app.use(RqAccess);
        app.use(RqDx);
        app.use(RqDialog);
        app.use(RqToast);
        app.use(RqUtils);
        app.use(RqApp);
        app.use(RqDirectives);
        app.use(RqVueEvents);

        // Register Global App Components
        for (var component in GlobalComponents) {
            if (Object.prototype.hasOwnProperty.call(GlobalComponents, component)) {
                app.component(component, GlobalComponents[component]);
            }
        }

        // Register vue-fontawesome components
        app.component("FontAwesomeIcon", FontAwesomeIcon);
        app.component("FontAwesomeLayers", FontAwesomeLayers);
        app.component("FontAwesomeLayersText", FontAwesomeLayersText);

        // Register bootstrap-vue-3 component aliases
        app.component("BBtn", BBtn);
        // app.component("BButton", BButton);
        // app.component("BAlert", BAlert);
        // app.component("BBadge", BBadge);
        // app.component("BCollapse", BCollapse);
        // app.component("BDropdown", BDropdown);
        // app.component("BFormCheckbox", BFormCheckbox);
        // app.component("BFormRadio", BFormRadio);
        // app.component("BNav", BNav);
        // app.component("BNavbar", BNavbar);
        // app.component("BLink", BLink);
        // app.component("BPopover", BPopover);
        // app.component("BTab", BTab);
    }
});
