<template>
    <div class="app">
        <router-view></router-view>
        <rq-dialog-manager />
        <rq-tooltip-manager />
        <rq-busy-manager />
    </div>
</template>

<script>
    import "devextreme/integration/jquery";

    import { computed, watch } from "vue";
    import { useStore } from "vuex";
    import { useRouter } from "vue-router";
    import { UserScreenAccessLevel } from "./shared/models/enums";
    import { ROOT_MUTATIONS } from "./store/mutations";
    import { GlobalEventManager, GLOBAL_EVENTS } from "./app.events";
    import { SET_PAGE_READ_ONLY, SET_CHECK_WRITING_IN_USE_PROMPT, SET_FILE_IN_USE_PROMPT } from "@/store/mutations";
    import { ORDER_ACTIONS } from "@/store/actions";

    import hideTopOverlay from "devextreme/mobile/hide_top_overlay";
    import { RqTooltipManager, RqBusyManager } from "./shared/components/rq/";
    import { useGlobalDialogs, useIdleJs } from "./shared/composables";
    import { useRqToast } from "./shared/plugins/composables";

    export default {
        name: "app",

        components: {
            RqTooltipManager,
            RqBusyManager
        },

        setup() {
            const store = useStore();
            const router = useRouter();

            const appError = computed(() => store.state.error);
            const fileInUsePrompt = computed(() => store.state.fileInUsePrompt);
            const readOnly = computed(() => store.state.isPageReadOnly);
            const isAuthenticated = computed(() => store.state.authentication.isAuthenticated);
            const user = computed(() => store.state.authentication.session.user);
            const orderId = computed(() => store.state.orders.orderId || 0);
            const orderSummary = computed(() => store.state.orders.orderSummary);
            const getRouteAccess = computed(() => store.getters.getRouteAccess);

            const { toastWarning } = useRqToast();

            const {
                openAuditLogDialog,
                openCdfEditorDialog,
                registerDialogEvents,
                unregisterDialogEvents
            } = useGlobalDialogs();

            const { startIdleJs } = useIdleJs({ notifyMsRemaining: 60000 });

            function handleAction(e) {
                if(!_.startsWith(e.key, "app-")) return;
                let currentRouteName = router.currentRoute.value.name;

                switch (e.key) {
                    case "app-audit-log":
                        openAuditLogDialog(e.props);
                        break;
                    case "app-nav-cdf-wysiwyg":{
                        if(currentRouteName == "oe:oc:buyer" || currentRouteName == "oe:oc:seller" || currentRouteName =="oe:op:property") {
                            // RQO-24243: handling component specifc save validation of Buyer/Seller
                            let sourceId = _.uniqueId("cdf-editor-launcher-");
                            const completedHandler = e => {
                                GlobalEventManager.offSaveCompleted(sourceId, completedHandler);
                                if(!e?.abort && e?.success) {
                                    openCdfEditorDialog();
                                }
                            };
                            GlobalEventManager.onSaveCompleted(sourceId, completedHandler);
                            GlobalEventManager.save({userInitiated: true, navigatingTo : 'cdf-wysiwyg'});
                        }
                        else if(orderId.value > 0)
                            openCdfEditorDialog(); 
                        else
                            router.push({
                                name: "settlement:settlement-dashboard",
                                query: { launchEditor: "cdf" }
                            });
                        break;
                    }
                }
            }

            return {
                fileInUsePrompt,
                readOnly,
                isAuthenticated,
                user,
                orderSummary,
                getRouteAccess,
                appError,
                startIdleJs,
                handleAction,
                hideTopDxOverlay: () => hideTopOverlay(),
                registerDialogEvents,
                unregisterDialogEvents,
                toastWarning,
                isFileAvailableShowing: false
            };
        },

        created() {
            const self = this;
            GlobalEventManager.onFireAction(self, self.handleAction);
            GlobalEventManager.onNavigated(self, self.navigated);

            self.scrollStartEventName =  self.$rqui?.GlobalEventNames?.RqScrollContainer?.scrollStart;
            if(_.isNil(self.scrollStartEventName)) return;
            self.$events.on(self.scrollStartEventName, self.hideTopDxOverlay);

            GlobalEventManager.on("rq-grid:save-grid-state", self.saveGridState);
        },

        mounted() {
            const self = this;
            if(!self.isAuthenticated) return;
            self.startIdleJs();
            self.setFileInUse(self.$route);
            self.registerDialogEvents();
        },

        beforeUnmount() {
            GlobalEventManager.unregister(this);
            this.unregisterDialogEvents();

            if(_.isNil(this.scrollStartEventName)) return;
            this.$events.off(this.scrollStartEventName, this.hideTopDxOverlay);
        },

        watch:{
            appError: {
                handler(newVal, oldVal){
                    const self = this;
                    if(newVal === oldVal || _.isEmpty(newVal)) return;
                    self.checkForErrorInfo();
                },
            },
            "user.usersID": {
                handler(newVal, oldVal){
                    const self = this;
                    let newId = _.parseNumber(newVal, 0);
                    let oldId = _.parseNumber(oldVal, 0);
                    if(newId <= 0 || newId === oldId) return;
                    self.$api.MessagingApi.getMessagingUrl().then((url) =>{
                        self.webSocket = new WebSocket(url);
                        self.webSocket.onopen = () => console.log('connected');
                        self.webSocket.onmessage = event => {
                            try{
                                let messageDto = JSON.parse(event.data);
                                self.handleMessage(messageDto);
                            }
                            catch(e){
                                console.log(e);
                                console.log(event);
                            }

                        };
                    });
                },
                immediate: true
            }
        },

        methods: {

            navigated: _.debounce(function(e) {
                let toRoute = _.get(e, 'to');
                let fromRoute = _.get(e, 'from');
                let toOrdersID = _.getNumber(toRoute, 'params.orderId', null);
                let fromOrdersID = _.getNumber(fromRoute, 'params.orderId', null);
                if (!_.isEqual(toOrdersID,fromOrdersID)) {//if they are switching to a new order, or a non order related page, reset the prompts
                    this.$store.commit(SET_FILE_IN_USE_PROMPT, true);
                    this.$store.commit(SET_CHECK_WRITING_IN_USE_PROMPT, true);
                }
                if (toRoute) {
                    this.saveActiveUser(toRoute)
                        .then(() => {
                            this.setFileInUse(toRoute);
                        })
                        .catch(error => {
                            console.error(error);
                            self.$toast.error({ message: `Error saving active user data.` });
                        });
                }
            }, 100, { 'leading': false, 'trailing': true }),

            async saveActiveUser(route) {
                const self = this;
                let logRouteAccess = _.getBool(route, "meta.logRouteAccess");
                if (!logRouteAccess) return;
                let ordersID = _.getNumber(route, 'params.orderId', null);
                let apiPromise = self.$api.ActiveUserApi.save(route.name, ordersID);
                return await self.$rqBusy.wait(apiPromise);
            },

            setFileInUse(route) {
                const self = this;
                let routeAccess = self.getRouteAccess(route);
                let isRouteReadOnly = routeAccess.accessLevel !== UserScreenAccessLevel.Full;
                if (_.getNumber(route, 'params.orderId', 0) > 0) {
                    //File Related
                    let isConsolidatedFile = _.getBool(self, "orderSummary.isConsolidatedFile");
                    if (isConsolidatedFile) {
                        self.$store.commit(ROOT_MUTATIONS.SET_PAGE_READ_ONLY, true);
                        self.$store.commit(ROOT_MUTATIONS.SET_PAGE_ACCESS_LEVEL, { key: "isConsolidatedFile", value: UserScreenAccessLevel.Read });
                        return;
                    }
                    let isFileInUse = _.getBool(self, "orderSummary.isFileInUse");
                    let ignoreFileInUse = _.getBool(self, "orderSummary.ignoreFileInUse");
                    let isFileLocked = _.getBool(self, "orderSummary.isLocked");
                    let isPageReadOnly = (isFileInUse && !ignoreFileInUse) || isFileLocked || isRouteReadOnly || (self.readOnly && route?.name === "o-inv:invoice"); 
                    self.$store.commit(SET_PAGE_READ_ONLY, isPageReadOnly);
                } else {
                    self.$store.commit(SET_PAGE_READ_ONLY, isRouteReadOnly);
                }
            },

            checkForErrorInfo() {
                const self = this;
                const errorIds = ["INACCESSIBLE_ROUTE", "INVALID_ORDER", "NO_LAST_ORDER"];
                let lastError = self.$store.getters.lastError;
                let displayError = "";

                if(_.isEmpty(lastError)) return;

                _.forEach(errorIds, errorId => {
                    if(!_.startsWith(lastError, errorId)) return;
                    displayError = _.replace(lastError, `${errorId}::`, "");
                    return false;
                });

                if(_.isEmpty(displayError)) return;

                self.toastWarning(displayError);
                self.$store.commit(ROOT_MUTATIONS.SET_ERROR, "");
            },

            handleMessage(message){
                const self = this;
                if(_.isNil(message)) return;
                let isFileInUse = _.getBool(self, "orderSummary.isFileInUse");
                let ignoreFileInUse = _.getBool(self, "orderSummary.ignoreFileInUse");
                if(ignoreFileInUse) return;
                switch(message.Event){
                    case "FileAvailable":
                        if(isFileInUse){
                            GlobalEventManager.emit(GLOBAL_EVENTS.FILE_AVAILABLE);
                            this.showFileAvailable(message);
                        }
                        break;
                }
            },

            showFileAvailable(message){
                const self = this;
                if(self.isFileAvailableShowing) return; //don't show this dialog again if it's already up
                let allowOpenFileInUse = self.user?.allowOpenFileInUse;
                if(allowOpenFileInUse) return; //don't show if the user can open files in use
                self.isFileAvailableShowing = true;
                let onYes = () => {
                    self.$store.commit(SET_FILE_IN_USE_PROMPT, false);
                    self.$store.commit(ROOT_MUTATIONS.SET_PAGE_READ_ONLY, false);
                    self.$store.dispatch(ORDER_ACTIONS.REFRESH_ORDER_SUMMARY, true);
                    self.isFileAvailableShowing = false;
                };
                let onNo = () =>{
                    self.isFileAvailableShowing = false;
                };
                let msg = `The file ${message.FileNumber} is now available. Would you like to edit the File now?`;
                self.$dialog.confirm("File now available", msg, onYes, onNo, { cancelTitle: 'No', okTitle: 'Yes'});
            },

            async saveGridState(gridState){
                const self = this;
                await self.$api.GridStatesApi.saveGridState(gridState.key, gridState.state);
            }
        }
    };

</script>
