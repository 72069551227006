import PropertiesApi from "@/api/properties";
import OrdersApi from "@/api/orders";
import { PropertyEntityModel } from "@order-entry/property/models"
import { PROPERTY_ACTIONS, ORDER_ACTIONS } from "../actions";
import { PROPERTY_MUTATIONS } from "../mutations";
import { BASIC_ACTION, ACTION_WITH_COMMIT, updateStateArray, removeFromStateArrayByValue, reorderStateArray } from "../helpers";

const ADDRESS_TYPE = { Primary: 0, Forwarding: 1 };

const state = {
    properties: [],
    currentProperty: null,
    briefLegalDescription: ""
};

const getters = {
    propertyList: (state) => {
        let result = [];
        _.each(state.properties, function (property) {result.push(new PropertyEntityModel(property.property));});

        return result;
    },
    propertyStates: state => _.filter(_.map(state.properties, p => p?.property?.state), s => !_.isEmpty(s))
};

const actions = {

    [PROPERTY_ACTIONS.GET_PROPERTIES](context, checkState=false) {
        let orderId = context.getters.orderId;
        if(checkState && !_.isEmpty(context.state.properties) && context.state.properties[0].ordersID === orderId) {
            return Promise.resolve(context.state.properties);
        }
        let apiPromise = PropertiesApi.getProperties(context.getters.orderId);
        return ACTION_WITH_COMMIT(context, apiPromise, PROPERTY_MUTATIONS.SET_PROPERTIES);
    },

    [PROPERTY_ACTIONS.GET_BRIEF_LEGAL_DESCRIPTION] (context, property) {
        return ACTION_WITH_COMMIT(context, PropertiesApi.getBriefLegalDescription(property), PROPERTY_MUTATIONS.SET_BRIEF_LEGAL_DESCRIPTION);
    },

    [PROPERTY_ACTIONS.ADD_PROPERTY](context, property) {
        let apiPromise = PropertiesApi.addProperty(property);
        return BASIC_ACTION(context, apiPromise, result => {
            context.dispatch(ORDER_ACTIONS.REFRESH_ORDER_SUMMARY, true);
        });
    },

    [PROPERTY_ACTIONS.COPY_BUYER_SELLER_ADDRESS] (context, { buyerSellerType, addressType, loanID, sequence }) {
        let ordersID = context.getters.orderId;
        let apiPromise = OrdersApi.getBuyerSellerAddress(ordersID, buyerSellerType);
        return new Promise((resolve,reject) => {
            BASIC_ACTION(context, apiPromise, result => {
                let property = new PropertyEntityModel({ ordersID, loanID, sequence });
                if(result) {
                    if(addressType === ADDRESS_TYPE.Primary) {
                        property.address1 = result.currentAddress1;
                        property.address2 = result.currentAddress2;
                        property.city = result.currentCity;
                        property.state = result.currentState;
                        property.countyID = result.countyID;
                        property.zip = result.currentZip;
                        property.propertyTypeID = result.propertyTypeID;
                        property.isForeignAddress = result.isForeignAddress;
                        property.country = result.currentCountry;
                        property.regionTerritory = result.currentRegionTerritory;
                    } else if(addressType === ADDRESS_TYPE.Forwarding) {
                        property.address1 = result.forwardingAddress1;
                        property.address2 = result.forwardingAddress2;
                        property.city = result.forwardingCity;
                        property.state = result.forwardingState;
                        property.countyID = result.countyID;
                        property.zip = result.forwardingZip;
                        property.propertyTypeID = result.propertyTypeID;
                        property.isForeignAddress = result.isForeignFowardingAddress;
                        property.country = result.forwardingCountry;
                        property.regionTerritory = result.forwardingRegionTerritory;
                    }
                }

                resolve({ property, taxInformation: [] });

                // Comment action to add property
                // context.dispatch(PROPERTY_ACTIONS.ADD_PROPERTY, property)
                //     .then(resolve)
                //     .catch(reject);
            });
        });
    },

    [PROPERTY_ACTIONS.UPDATE_PROPERTY] (context, { data, changes }) {
        let apiPromise = PropertiesApi.updateProperty(data, changes);
        return BASIC_ACTION(context, apiPromise, result => {
            context.dispatch(ORDER_ACTIONS.REFRESH_ORDER_SUMMARY, true);
        });
    },

    [PROPERTY_ACTIONS.DELETE_PROPERTY](context, ids) {
        let apiPromise = PropertiesApi.deleteProperty(context.getters.orderId, ids);
        return BASIC_ACTION(context, apiPromise, (result) => {
            context.dispatch(ORDER_ACTIONS.REFRESH_ORDER_SUMMARY, true);
        });
    }
};

const mutations = {
    [PROPERTY_MUTATIONS.SET_PROPERTIES] (state, value) { _.set(state, "properties", value); },
    [PROPERTY_MUTATIONS.ADD_ORDER_PROPERTY] (state, value) { state.properties.push(value); },
    [PROPERTY_MUTATIONS.UPDATE_ORDER_PROPERTY] (state, value) {
        let propIndex = _.findIndex(state.properties, (i) => {
            return _.get(i, "property.propertyID", -1) === _.get(value, "property.propertyID", -1);
        });
        if (propIndex >= 0)
            state.properties.splice(propIndex, 1, value);
    },
    [PROPERTY_MUTATIONS.REMOVE_ORDER_PROPERTY] (state, values) {
        for(let i = 0; i< values.length; i++)
        {
            removeFromStateArrayByValue(state.properties, "property.propertyID", values[i]);
            reorderStateArray(state.properties, "property.sequence", 1);
        }
    },
    [PROPERTY_MUTATIONS.SET_BRIEF_LEGAL_DESCRIPTION] (state, value) { state.briefLegalDescription = value; }
};

export default {
    state,
    getters,
    actions,
    mutations
};