<template>
    <rqdx-action-data-grid
        ref="dataGrid"
        automation_id="grid_address_book"
        :config="gridConfig"
        v-model:search-value="searchText"
        :data-source="gridDataSource"
        export-file-name="address_book_contact"
        fixed-header
        search-mode="field"
        @clear-search="onClearSearch"
    />
</template>
<script>
    export default {
        props: { args: {} },

        data () {
            return {
                orderId: 0,
                availableUsers: [],
                emailList: null,
                searchText: ""
            };
        },

        created() {
            const self = this;
            let componentArgs = _.assign({}, {
                orderId: _.get(self, "$store.state.orders.orderId", 0)
            }, self.args);

            self.orderId = componentArgs.orderId;
            self.initGridConfig();
            self.availableUsers = componentArgs.addressBookContacts;
            self.refreshGrid();
        },

        watch: {
            searchText(newValue, oldValue) {
                if(newValue === oldValue) return;
                this.gridSearch(newValue);
            }
        },

        methods: {
            initGridConfig(){
                const self = this;
                self.gridConfig = {
                    remoteOperations: { sorting: false, paging: false },
                    columns: [
                        { dataField: "fullName", caption: "Name" },
                        { dataField: "contactType", caption: "Role"},
                        { dataField: "emailAddress", caption: "Email Address"}
                    ]
                };
                self.gridDataSource = {
                    key: "id",
                    load (loadOptions) {
                        return Promise.resolve(self.availableUsers.slice());
                    }
                };

            },

            getSelected() {

                let gridInstance = _.get(this.$refs, "dataGrid.gridInstance", null);
                if(!_.isNil(gridInstance)) {
                    return _.map(gridInstance.getSelectedRowsData(), x => x.emailAddress);
                }
                return [];
            },

            onClearSearch(e){
                this.searchText = "";
                this.refreshGrid();
            },

            refreshGrid() {
                let gridInstance = _.get(this.$refs, "dataGrid.gridInstance", null);

                if(!_.isNil(gridInstance)) {
                    gridInstance.refresh();
                    gridInstance.clearSelection();
                }
            },

            gridSearch: _.debounce(function(searchTextValue)
            {
                let gridInstance = _.get(this.$refs, "dataGrid.gridInstance", null);

                if(!_.isNil(gridInstance)) {
                    gridInstance.searchByText(searchTextValue);
                }
            }, 300),
        }
    };
</script>